import React, { useEffect, useState } from 'react';
import { Row, Table, Col, Result, Typography, Layout } from 'antd';
import { ExportModalMaps, PDFMapsButton } from '..';
import './StructureCommercial.css';
import { addCommasToFloat, reduceDecimalsOfFloat } from 'utils';

const StructureCommercial = ({ filters, mapsState, getfile }) => {
  const [titlesTable, setTitlesTable] = useState({ Label: '' });
  const [titleTotal, setTitleTotal] = useState('');
  const [challengeTotal, setChallengeTotal] = useState('');
  const [titleColumn, setTitleColumn] = useState('');
  const { Text } = Typography;

  //Filters_
  useEffect(() => {
    let titleTotalColumn = '';
    if (filters?.agreementChallenge === 1) {
      titleTotalColumn = 'Total de acuerdo comercial ';
    } else if (filters?.agreementChallenge === 2) {
      titleTotalColumn = 'Total desafío de ventas';
    } else {
      titleTotalColumn = '';
    }

    // excel column3
    let column3 = filters?.agreementChallenge === 1 ? 'acuerdo comercial ' : 'desafío de ventas';
    setTitleColumn(column3);

    let challenge = filters?.agreementChallenge;
    setChallengeTotal(challenge);

    //Title Table

    setTitleTotal(titleTotalColumn);

    if (filters?.zones?.length) {
      setTitlesTable({ Label: 'Zona' });
    } else if (filters?.areas?.length) {
      setTitlesTable({ Label: 'Territorio' });
    } else if (filters?.subregions?.length) {
      setTitlesTable({ Label: 'Subregión' });
    } else if (filters?.bussinesLine?.length) {
      setTitlesTable({ Label: 'Línea de negocio' });
    } else if (filters?.regions?.length) {
      setTitlesTable({ Label: 'Región' });
    }
  }, [filters]);

  //MapState

  let dataSource = [];

  if (mapsState.get) {
    // setDisabled(false)
    dataSource = mapsState.get.map((item) => {
      if (challengeTotal === 2) {
        return {
          Label: item.label,
          Total: `$${addCommasToFloat(reduceDecimalsOfFloat(item.challenge))}`,
          Total2: item.challenge,
          Color: item.color,
        };
      } else {
        return {
          Label: item.label,
          Total: `$${addCommasToFloat(reduceDecimalsOfFloat(item.agreement))}`,
          Total2: item.agreement,
          Color: item.color,
        };
      }
    });
  }

  //Excel Data
  let dataExcel = [];
  if (mapsState.get) {
    // setDisabled(false)
    dataExcel = mapsState.get.map((item) => {
      if (challengeTotal === 2) {
        return {
          Label: item.label,
          Total: `$${addCommasToFloat(reduceDecimalsOfFloat(item.challenge))}`,
          Total2: item.challenge,
        };
      } else {
        return {
          Label: item.label,
          Total: `$${addCommasToFloat(reduceDecimalsOfFloat(item.agreement))}`,
          Total2: item.agreement,
        };
      }
    });
  }

  const handleExportExcel = () => {
    getfile({
      Values: dataExcel,
      Column1: titlesTable.Label,
      Column2: titleColumn,
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'Color',
      key: 'Color',
      className: 'row-head-color',
      width: 10,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return (
          <div
            style={{ height: '27px', width: '16px', backgroundColor: text, textRight: 'right' }}
          />
        );
      },
    },
    {
      title: titlesTable.Label,
      dataIndex: 'Label',
      key: 'Label',
      className: 'row-head',
    },
    {
      title: titleTotal,
      dataIndex: 'Total',
      key: 'Total',
      className: 'row-head',
    },
  ];

  return (
    <Layout>
      <Row>
        <Col id="table" xs={24} md={24} lg={13}>
          <Table
            locale={{
              emptyText: (
                <Result
                  status="500"
                  subTitle="No se encontraron resultados que coincidan con los criterios de búsqueda ingresados"
                />
              ),
            }}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowClassName="row-style"
            className="custom-table"
            summary={(pageData) => {
              let totalTable = 0;

              pageData.forEach(({ Total2 }) => {
                totalTable += Total2;
              });

              return (
                <>
                  <Table.Summary.Row className="total-row">
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text className="total-number">{`$${addCommasToFloat(
                        reduceDecimalsOfFloat(totalTable)
                      )}`}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>

      <Row>
        <ExportModalMaps doExport={() => handleExportExcel()} />
      </Row>
      <Row>
        <PDFMapsButton />
      </Row>
    </Layout>
  );
};

export default StructureCommercial;
